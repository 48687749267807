
.flush-with-header {
	margin-top: -$standard-margin;
}

.section-masthead {
	@include gradient-y(#161618, #1D1E22);
	color: var(--bs-body-color);
	padding: 2em 0;

	.salespitch {
		> * {
			margin-top: 0;
			margin-bottom: 0;
		}
		.title        { font-size: 40px; margin: 0; padding-bottom: 5px; color: white; }
		.tagline      { font-size: 28px; margin: 0; padding-bottom: 30px; }
		.feature-list { margin: 0; padding-bottom: 30px; }
		.feature      { font-size: 21px; font-weight: 300; }
	}

	.panel-masthead {
		margin: 15px 0;
	}

	@media (max-width: 767px) {
		padding: 0.5em 0;

		.salespitch {
			.title { font-size: 30px; padding-bottom: 0; }
			.tagline { font-size: 24px; padding-bottom: 10px; }
			.feature-list { padding-bottom: 10px; }
			.feature { font-size: 18px; }
		}
	}
}

.section-screenshots {
	@include gradient-y(#000, #111);
	color: var(--bs-body-color);
	padding: 3em 0;

	.img-thumbnail {
		background: #222;
		border: 0;
		padding: 0;
	}
}

.section-features {
	@include gradient-y(#333, #444);
	color: var(--bs-body-color);
	padding: 2em 0;
}
