
$lightbox-border-width: 6px;

.lightbox-backdrop {
	background: rgba(0, 0, 0, 0.75);
	height: 120%; /* more than 100% because of Chrome mobile */
	position: fixed;
	top: 0px;
	width: 100%;
	z-index: 11;
}

.lightbox {
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	position: fixed;
	top: 0px;
	width: 100%;
	z-index: 10;
}

.lightbox-container {
	text-align: center;
	z-index: 12;
}

.lightbox-content {
	background: black;
	display: inline-block;

	img {
		max-width: 100%;
	}
}

.lightbox-description {
	color: white;
	margin-top: 0.5em;
	text-align: right;
}

.lightbox-back,
.lightbox-forward {
	opacity: 0.4;
	position: absolute;
	z-index: 13;
	-webkit-text-stroke: 1px black;

	&:hover {
		opacity: 1;
	}
}

.lightbox-back {
	left: 5%;
}

.lightbox-forward {
	right: 5%;
}
