
html.no-script {
	.script-required {
		display: none;
	}
}

.light {
	opacity: 0.65;
}

.strong {
	font-weight: 600;
}
