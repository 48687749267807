
.tag {
	margin: 0 5px 2px 0;
}

a.tag {
	@include plain-link;

	color: #222;
}

.station-link {
	@include plain-link;
}

.station-list-row {
	border: 1px solid transparent;
	padding: 5px 0;
	position: relative;

	&:hover {
		background: #fafafa;
		border-color: #efefef;
	}

	@media (min-width: 992px) {
		padding-right: 75px;
	}
}

.station-list-controls {
	position: absolute;
	top: 10px;
	right: 0;
}

.deleted {
	opacity: 0.5;
}

.inactive .station-link,
.inactive .tag-link {
	color: #b33;
}
