
header {
	@include gradient-y(#eee, #fff);

	.nav-link {
		padding: 0.5rem 0.65rem;
	}

	.dropdown-toggle {
		background-color: transparent !important;
		color: black;

		&:hover {
			background-color: rgba(0, 0, 0, 0.1) !important;
		}
	}

	.quick-search {
		margin: 3px 10px 0 10px;
		width: 220px;
	}

	.header-row {
		background: white;
		border-bottom: 1px solid $bright-orange;

		&.quicksearch {
			background: transparent;
			height: 0;
			overflow: hidden;

			transition: all 0.5s;

			&.visible {
				height: 330px;
			}
		}
	}

	@media (max-width: 767px) {
		.quicksearch.visible {
			overflow-y: scroll;
		}
	}
}

.nav.site-navigation {
	margin-bottom: 0;
	position: relative;

	a:not(.dropdown-toggle) {
		@include plain-link;

		color: black;

		&:hover {
			background: transparent;
			color: #005580;
		}
	}

	.brand {
		font-weight: 600;
		font-size: 21px;

		a {
			padding-left: 0;
			padding-top: 4px;
			padding-bottom: 5px;
		}
	}

	.nav-item-user {
		position: absolute;
		right: 0;
	}
}
