
.card {

	&.condensed  {
		.card-body {
			padding: 10px;
		}
	}

	&.purple {
		border-top-color: $purple;
		border-top-width: 2px;
	}

	&.focus,
	&.blue {
		border-top-color: $bright-blue;
		border-top-width: 2px;
	}

	&.install,
	&.green {
		border-top-color: $bright-green;
		border-top-width: 2px;
	}

	&.error {
		border-top-color: $strong-orange;
		border-top-width: 2px;
		margin-top: -1px;
	}
}
