
.notification-container {
	position: fixed;
	top: 20px;
	left: 20px;
	right: 20px;

	@media (min-width: 400px) {
		left: auto;
		width: 400px;
	}

	ul {
		margin-bottom: 0;
	}
}
