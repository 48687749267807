// Bootstrap overrides
//
// Copy variables from `_variables.scss` to this file to override default values
// without modifying source files.

// Default colors
$purple: #a5a5bd;
$orange: #e0880c;

// Custom colors
$bright-blue:   #cde;
$bright-orange: #ff8f00;
$bright-green:  #aca;
$muted-blue:    #337ab7;
$smoky-dark:    #101010;
$smoky-white:   #fafafa;
$strong-orange: #f74;

// Branding
$primary:       $blue;
$warning:       $orange;
$danger:        $red;

// Reapply theme
$theme-colors: ();
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
  ),
  $theme-colors
);

$body-bg: $smoky-white;
$body-bg-dark: $smoky-dark;

// Links
$link-color:       $muted-blue;
$link-hover-color: darken($link-color, 15%);

// Other elements
$hr-color:         $gray-600;

// Fonts
$font-family-sans-serif: "Open Sans", sans-serif;
$font-family-base:       $font-family-sans-serif;

// Custom variables
$standard-margin: 20px;
