
$errorColor: #e9322d;

.field-validation-error
{
	color: $errorColor;
}

.field-validation-valid
{
	display: none;
}

input.ng-invalid,
.input-validation-error,
input[type=text].input-validation-error,
input[type=password].input-validation-error
{
	border: 1px solid $errorColor !important;
	background-color: #fffafa;
}

.validation-summary-errors
{
	font-weight: bold;
	color: $errorColor;
}

.validation-summary-valid
{
	display: none;
}
