
.site-footer {

	.footer-gradient {
		@include gradient-y(#0a0a0a, #1a1a1a);
	}

	.btn-link {
		@include plain-link;

		color: #ccc !important;
		padding-top: 3px;
		padding-bottom: 3px;
	}
}
