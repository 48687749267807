
.site-container {
	display: flex;
	height: 100vh;
	flex-direction: column;
}

section, header, article, .card {
	margin-bottom: $standard-margin;
}

.large-text {
	font-size: 20px;
	font-weight: 400;
	line-height: 1.5;
}
